<template>
  <div class="purchase">
    <img src="@/assets/images/image-page.png" class="image-page" alt="" />
    <header-page> Pemesanan </header-page>
    <b-container class="mt-15 pb-15">
      <b-row class="justify-content-center mb-2">
        <b-col cols="12" lg="3" md="6">
          <router-link to="/purchase/pemesanan-sistem">
            <b-card
              class="border-12 mb-0"
              style="height: 85px; cursor: pointer"
            >
              <div class="d-flex align-items-center h-100" style="gap: 14px">
                <img
                  src="@/assets/images/icons/add-user.svg"
                  alt=""
                  style="width: 46px; height: 46px"
                />
                <div class="d-flex flex-column">
                  <h5 class="text-dark size14 fw-bold-800 mb-0">
                    Pemesanan Sistem
                  </h5>
                </div>
              </div>
            </b-card>
          </router-link>
        </b-col>
        <b-col cols="12" lg="3" md="6">
          <b-card class="border-12 mb-0" style="height: 85px; cursor: pointer">
            <div class="d-flex align-items-center h-100" style="gap: 14px">
              <img
                src="@/assets/images/icons/add-cabang.svg"
                alt=""
                style="width: 46px; height: 46px"
              />
              <div class="d-flex flex-column">
                <h5 class="text-dark size14 fw-bold-800 mb-0">
                  Pemesanan Manual
                </h5>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  BModal,
  BContainer,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BImg,
  BCard,
  BButton,
  BFormCheckbox,
  BFormRadio,
  BSpinner,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";
import HeaderPage from "@/components/HeaderPage.vue";

import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    vSelect,
    BImg,
    BCard,
    BButton,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BContainer,
    BFormCheckbox,
    BFormRadio,
    BSpinner,
    BFormSelect,
    HeaderPage,
  },
  data() {
    return {};
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getUser();
    this.getBranch();
  },
  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.purchase .image-page {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.card-body {
  padding: 1rem;
}

.selected {
  box-shadow: 0 10px 10px 0 #52cec2;
}
</style>
